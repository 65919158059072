.molecule-toparticlesgroup {

  h4 {
    font-size: 2.4rem;
  }

  &__top, &__paid {
    .molecule-toparticles__list {
      margin-bottom: 0;
      border-bottom: none;
      border-top: none;
    }
  }
  &__top {
    order: 2;
  }

  &__paid {
    order: 1;
    .h2 {
      padding-top: 2rem;
    }
    .molecule-teaser__omplus {
      display: inline-block;
    }
  }
}
.molecule-toparticles__bluelight {
  padding: 0;
}
