.molecule-paywall {
  text-align: center;
  margin: 0 0 4rem;

  &__gradient {
    background: $color-white;
    background: linear-gradient(0deg, rgba($color-white,1) 0%, rgba($color-white,0) 100%);
  }

  &__container {
    display: flex;
  }

  &__img {
    display: none;
    width: 33%;
  }

  &__content {
    color: $color-white;
    font-size: $default-font-size;
    flex: 1;
    padding: 2rem 1.5rem;
    background-color: #008CFA; /* TODO: variable? */

    h2 {
      font-weight: 700;
    }
  }

  &__price {
    margin-top: 1.5rem;
    font-size: 5rem;
    line-height: 5rem;

    span {
      font-size: $default-font-size;
    }
  }

  &__list {
    text-align: left;
    padding: 0 15%;
    line-height: normal;
    list-style: none;

    li {
      margin-bottom: .5rem;
      &:before {
        content: ''!important;
      }
    }
  }

  &__link {
    color: #008CFA; /* TODO: variable? */
    font-weight: 700;
    background-color: $color-white;
    padding: .3rem 2rem;
    margin: 1rem 0;
    min-width: 14.5rem;
    display: inline-block;
    border: 2px solid transparent;

    &:hover {
      color: $color-white;
      background-color: transparent;
      border: 2px solid $color-white;
      text-decoration: none;
    }
    &__info {
      display: block;
      font-size: 1.4rem;
      margin: 0 -20%;
      padding-bottom: 10px;
      a {
        color: $color-white;
        font-weight: bold;
        text-decoration: underline;
        &:hover {
          color: $color-white;
          text-decoration: none;
        }
      }
      &.show {
        display: block;
      }
    }
  }

  svg {
    fill: $color-white !important;
  }

  &__box {
    padding: 1rem 0;
    margin: 4rem 0 0;
    border: 2px solid $color-white;

    .atom-logo {
      position: relative;

      span {
        position: absolute;
        top: -3rem;

        svg {
          width: 13rem;
          height: 3.5rem;
          background: #008CFA; /* TODO: variable? */
        }
      }
    }

    .om-font-icon {
      margin-left: -3rem;
      font-size: 2.5rem;
      vertical-align: middle;
    }

  }

  &__price-after-testmonth {
    font-size: 1.3rem;
    margin: 0 1rem 2rem;
  }

  &__abo {

    .font-small {
      text-align: left;
    }
  }

  .font-small {
    font-size: 1.3rem;
    margin: 1rem 1rem 0;
  }

  &__logos {
    display: flex;

    div {
      padding: 0 .5rem;
    }

    .atom-logo {
      height: 4rem;
    }
  }


}
