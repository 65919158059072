@include media-breakpoint-up(md) {
  .om-font-icon {
    /* Twitter filled */
    &.icon-200302_iconfont-15 {
      @include fa-icon-brands($fa-var-x-twitter);
      display: inline-block;
      height: 35px;
      width: 35px;
      background-color: #fff;
      border-radius: 50%;
      position: relative;
      line-height: 1;
      margin-bottom: 12px!important;
      &:before {
        font-size: 1.9rem;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.8rem;
        color: $color-main;
        line-height: 1;
      }
    }

    &.icon-200302_iconfont-linkedin {
      @include fa-icon-brands($fa-var-linkedin-in);
      display: inline-block;
      height: 35px;
      width: 35px;
      background-color: #fff;
      border-radius: 50%;
      position: relative;
      line-height: 1;
      margin-bottom: 12px!important;
      &:before {
        font-size: 1.9rem;
        position: absolute;
        top: 1px;
        left: 2px;
        padding: 0.8rem;
        color: $color-main;
        line-height: 1;
      }
    }

  }
  .molecule-sharearticle .om-font-icon {
    &.icon-200302_iconfont-linkedin2:before,
    &.icon-200302_iconfont-03:before {
      font-size: 2.5rem;
      position: absolute;
      bottom: -9px;
      left: 4px;
    }
  }

}
@include media-breakpoint-up(lg) {
  .om-font-icon {
    /* Twitter filled */
    &.icon-200302_iconfont-15,
    &.icon-200302_iconfont-linkedin{
      margin-bottom: -5px!important;
    }
  }
  .molecule-sociallinks .om-font-icon {
    &.icon-200302_iconfont-linkedin2:before,
    &.icon-200302_iconfont-03:before {
      font-size: 1.8rem;
      position: absolute;
      bottom: 2px;
      left: 8px;
    }
  }
}