.molecule-sharearticle {
  font-size: $default-font-size;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
  margin-top: 4rem;
  border-top: 1px solid $color-main;
  border-bottom: 1px solid $color-main;

  &__shareicons {
    display: flex;
  }

  .om-font-icon {
    color: $color-main;
    font-size: 4rem;
    margin: 0 .25rem
  }

  &__sharelink {
    display: inline-block;

    &.copyUrl {

      svg {
        width: 3rem;
        fill: $color-main;
        height: 1.9rem;
        padding-top: .3rem;
        margin: 1rem .5rem;
      }

      &:hover{

        svg {
          fill: $color-secondary;
        }
      }
    }

    &.whatsapp {
      display: block;
    }

    &:hover {
      text-decoration: none;

      .om-font-icon,
      .om-font-icon:before {
        color: $color-secondary;
      }
    }
  }

}