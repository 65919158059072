@include media-breakpoint-up(md) {
  .molecule-toparticlesgroup {

    &__top {
      padding-right: .5rem;

      .molecule-toparticles__list {
        margin-bottom: 1rem;
      }
    }
    &__top {
      order: 1;
    }

    &__paid {
      order: 2;
      padding-left: .5rem;
      .h2 {
        padding-top: 0;
      }
    }
  }

  .molecule-toparticles__bluelight {
    padding: 0 0 0 15px;
  }
}
