.molecule-teaseroffer {
  text-align: center;
  margin: 0 0 4rem;

  &__gradient {
    height: 10rem;
    background: $color-white;
    background: linear-gradient(0deg, rgba($color-white,1) 0%, rgba($color-white,0) 100%);
  }

  &__container {
    display: flex;
  }

  &__img {
    display: none;
    width: 33%;
  }

  &__content {
    color: $color-white;
    font-size: $default-font-size;
    flex: 1;
    padding: 2rem 1.5rem;
    background-color: #008CFA;

    h2 {
      font-weight: 700;
    }
  }

  &__price {
    margin-top: 1.5rem;
    font-size: 5rem;
    line-height: 5rem;

    span {
      font-size: $default-font-size;
    }
  }

  &__price-after-testmonth {
    font-size: 1.3rem;
    margin: 0 1rem 2rem;
  }

  &__list {
    text-align: left;
    line-height: normal;
    margin-top: 1rem;
    padding-left: 3rem;
    list-style: none;

    li {
      margin-bottom: .5rem;
    }
  }

  &__boxheading {
    display: block;
    font-size: 2rem;
    text-align: left;
    padding: 0 2rem;
  }

  &__link {
    color: #008CFA;
    font-weight: 700;
    background-color: $color-white;
    padding: 0 3rem;
    margin: 1rem 0;
    min-width: 14.5rem;
    width: 100%;
    display: inline-block;
    border: 2px solid transparent;
    flex: 1;

    &:hover {
      color: $color-white;
      background-color: transparent;
      border: 2px solid $color-white;
      text-decoration: none;
    }
  }

  &__listcontainer {
    padding: 0 2rem 1rem;
    display: block;

    .molecule-teaseroffer__link {
      font-size: 2rem;
      padding: 0;
    }
  }

  svg {
    fill: $color-white !important;
  }

  &__wrapper {
    flex: 1;
    .molecule-paywall__link__info {
      text-align: left;
      margin: 0;
    }
  }

  &__box {
    padding: 1rem 0;
    margin: 4rem 0 0;
    border: 2px solid $color-white;

    .atom-logo {
      position: relative;

      span {

        svg {
          width: 13rem;
          height: 3.5rem;
          background: #008CFA;
        }
      }
    }

    .om-font-icon {
      margin-left: -3rem;
      font-size: 2.5rem;
      vertical-align: middle;
    }

  }

  &__logocontainer {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    margin-top: -29px;
  }

  &__heading {
    background-color: #008cfa;
    padding: 0 1rem;
    align-self: center;
    font-size: 2rem;
    font-family: SourceSansPro-Bold,sans-serif;
    display: none;
  }

  &__abo {
    margin-top: 2rem;
    padding: 0;

    .font-small {
      text-align: left;
    }
  }

  &__linksmobile {
    display: flex;

    a {
      font-size: 1.5rem;
      padding: 0;

      &:first-child {
        margin-right: .5rem;
      }
    }
  }

  &__linkstablet {
    display:  none;
  }

  .font-small {
    font-size: 1.3rem;
    margin: 1rem 0 0;
  }

  &__logos {
    display: flex;

    div {

      &:first-child {
        padding-right: .5rem;
      }
    }

    .atom-logo {
      height: 4rem;
    }
  }

  &__footerlogos {
    display: block;
  }
}
